import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

// import * as bootstrap from 'bootstrap'

window.addEventListener('DOMContentLoaded', (e) => {
  const nav = document.querySelector('header');
  if (window.pageYOffset > 0) {
    nav.classList.add("navbar-onscroll");
  } else {
    nav.classList.remove("navbar-onscroll");
  }

  setActiveNavLink();
});

window.addEventListener('scroll', (e) => {
  const nav = document.querySelector('header');
  if (window.pageYOffset > 0) {
    nav.classList.add("navbar-onscroll");
  } else {
    nav.classList.remove("navbar-onscroll");
  }
});

// Event listener for URL hash changes to update the active navbar link
window.addEventListener('hashchange', setActiveNavLink);

function setActiveNavLink() {
  // Get the current hash in the URL
  const currentHash = window.location.hash;

  // Select all nav links
  const navLinks = document.querySelectorAll('header .navbar-nav .nav-link');

  // Remove active class from all nav links
  navLinks.forEach(link => {
    link.classList.remove('active');
    link.removeAttribute('aria-current');
  });

  // Set the active class to the matching nav link
  if (currentHash) {
    const activeLink = document.querySelector(`header .navbar-nav .nav-link[href="${currentHash}"]`);
    if (activeLink) {
      activeLink.classList.add('active');
      activeLink.setAttribute('aria-current', 'page');
    }
  } else {
    // If there's no hash in the URL, default to the HOME link
    const homeLink = document.querySelector('header .navbar-nav .nav-link[href="#"]');
    if (homeLink) {
      homeLink.classList.add('active');
      homeLink.setAttribute('aria-current', 'page');
    }
  }
}